import React from 'react';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Box, Button, Grid } from '@core';
import {
  Animation,
  AvailablePlatforms,
  DealsTimerRedesigned,
  Icon,
  LinkWrapper,
  StoreRatings,
  Text,
} from '@components';
import { ImageCore } from '@components/image';
import { usePlansValuesContext } from '@context';
import { isClient } from '@shared/helpers';

import { AnimatedBFImage } from '../../components';

const CTAImage = styled(ImageCore)`
  object-fit: cover;
`;

const DealsTextBlock = ({ data, showTimer }) => {
  const { t } = useTranslation();

  const {
    isLoading: isLoadingFromContext,
    suggestedPlan: {
      coupon,
      currency,
      discountPercentage,
      extraTime,
      pricePerMonth,
      subscriptionPeriod,
    } = {}, // default value for cms preview to work while loading plans
  } = usePlansValuesContext();

  const isLoading = !isClient || isLoadingFromContext;

  const {
    title,
    subtitle,
    isSubtitleDynamic = true,
    button,
    description,
    infoText,
  } = data.text({
    pricePerMonth,
    discountPercentage,
    couponCode: coupon,
    currency,
    subscriptionPeriod,
    extraTime,
    t,
  });

  return (
    <Animation.FadeIn>
      {title && <Text.Heading1 id="deals-header-title-desktop">{title}</Text.Heading1>}

      {subtitle && (
        <Text.Body1Strong
          as="div"
          minHeight={32}
          fontSize={22}
          mt={16}
          id="deals-header-subtitle-desktop"
        >
          {isSubtitleDynamic
            ? !isLoading && <Animation.FadeIn>{subtitle}</Animation.FadeIn>
            : subtitle}
        </Text.Body1Strong>
      )}

      {showTimer && (
        <Grid.Row mt={24}>
          <DealsTimerRedesigned />
        </Grid.Row>
      )}

      {button && (
        <Grid.Row mt={32}>
          <Box minWidth={280}>
            <LinkWrapper link={button.link} withParams>
              <Button
                type="button"
                size="large"
                fullWidth
                variant={button.buttonProps?.variant}
                color={button.buttonProps?.color}
                {...button?.buttonProps}
              >
                {button.title}
              </Button>
            </LinkWrapper>
          </Box>
        </Grid.Row>
      )}

      {description?.map((item, index) => (
        <Box display="flex" alignItems="center" mt={16} key={index}>
          <Box display="flex" mr={12}>
            <Icon.Check size={16} color="success" />
          </Box>
          <Text.Body2>{item}</Text.Body2>
        </Box>
      ))}

      {infoText && <Text.Body1 mt={16}>{infoText}</Text.Body1>}
    </Animation.FadeIn>
  );
};

const DealsCTADesktop = ({ data, showTimer }) => {
  const theme = useTheme();

  const { desktop: image, isImageAnimated = false } = data.image();

  return (
    <Grid.Container pt={40} pb={8} px={40} position="relative">
      <Grid.Row>
        <Grid.Col maxWidth={565} minHeight={430}>
          <DealsTextBlock data={data} showTimer={showTimer} />
        </Grid.Col>
        <Grid.Row pt={86} maxWidth={1200} justifyContent="space-between">
          <Box display="flex" columnGap={64}>
            <StoreRatings.Apple variant={theme.variants?.storeRatings} />
            <StoreRatings.Google variant={theme.variants?.storeRatings} />
          </Box>
          <AvailablePlatforms variant={theme.variants?.availablePlatforms} />
        </Grid.Row>
      </Grid.Row>

      {isImageAnimated ? (
        <AnimatedBFImage image={image} />
      ) : (
        <CTAImage
          id="header-img"
          src={image?.src}
          alt={image?.alt}
          loading={image?.loading}
          position="absolute !important" // !important is for gatsby image to work properly in build
          zIndex={-1}
          left="50%"
          {...image?.position}
        />
      )}
    </Grid.Container>
  );
};

export default DealsCTADesktop;

import React from 'react';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Box, Button, Grid, Show } from '@core';
import {
  Animation,
  AvailablePlatforms,
  DealsTimerRedesigned,
  Icon,
  LinkWrapper,
  StoreRatings,
  Text,
} from '@components';
import { ImageCore } from '@components/image';
import { usePlansValuesContext } from '@context';
import { isClient } from '@shared/helpers';

const Image = styled(ImageCore)`
  align-self: flex-start; // to keep image aspect ratio on safari
  object-fit: cover;
`;

const DealsTextBlock = ({ data, showTimer }) => {
  const { t } = useTranslation();

  const {
    isLoading: isLoadingFromContext,
    suggestedPlan: {
      coupon,
      currency,
      discountPercentage,
      extraTime,
      pricePerMonth,
      subscriptionPeriod,
    } = {}, // default value for cms preview to work while loading plans
  } = usePlansValuesContext();

  const isLoading = !isClient || isLoadingFromContext;

  const {
    title,
    subtitle,
    isSubtitleDynamic = true,
    button,
    description,
    infoText,
  } = data.text({
    pricePerMonth,
    discountPercentage,
    couponCode: coupon,
    currency,
    subscriptionPeriod,
    extraTime,
    t,
  });

  return (
    <Animation.FadeIn>
      <Box px={24}>
        {title && (
          <Text.Heading1 textAlign="center" id="deals-header-title-tablet">
            {title}
          </Text.Heading1>
        )}

        {subtitle && (
          <Text.Subheading2
            as="div"
            minHeight={32}
            textAlign="center"
            mt={16}
            id="deals-header-subtitle-tablet"
          >
            {isSubtitleDynamic
              ? !isLoading && <Animation.FadeIn>{subtitle}</Animation.FadeIn>
              : subtitle}
          </Text.Subheading2>
        )}
      </Box>
      {showTimer && (
        <Grid.Row justifyContent="center" mt={32}>
          <DealsTimerRedesigned />
        </Grid.Row>
      )}

      {button && (
        <Grid.Row justifyContent="center" mt={32}>
          <Box minWidth={280}>
            <LinkWrapper link={button.link} withParams>
              <Button type="button" size="large" fullWidth {...button.buttonProps}>
                {button.title}
              </Button>
            </LinkWrapper>
          </Box>
        </Grid.Row>
      )}

      {description && (
        <Grid.Container>
          {description.map((item, index) => (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width={{ _: 320, sm: '100%' }}
              mt={16}
              key={index}
            >
              <Box display="flex" mr={12}>
                <Icon.Check size={16} color="success" />
              </Box>
              <Text.Body2>{item}</Text.Body2>
            </Box>
          ))}
        </Grid.Container>
      )}

      {infoText && (
        <Text.Body1 textAlign="center" mt={32}>
          {infoText}
        </Text.Body1>
      )}
    </Animation.FadeIn>
  );
};

const DealsCTATablet = ({ data, showTimer, showStoreRatings }) => {
  const theme = useTheme();

  const { mobile, tablet } = data.image();

  return (
    <Grid.Container>
      <Box width="100%" maxWidth={640} mb={!showStoreRatings && 32}>
        <Grid.Container minHeight={showStoreRatings && 350}>
          <DealsTextBlock data={data} showTimer={showTimer} />
        </Grid.Container>

        {showStoreRatings && (
          <Grid.Row
            justifyContent="space-evenly"
            mt={56}
            mb={32}
            px={{ _: 24, md: 40 }}
            columnGap={16}
            rowGap={24}
          >
            <StoreRatings.Apple variant={theme.variants?.storeRatings} />
            <StoreRatings.Google variant={theme.variants?.storeRatings} />
          </Grid.Row>
        )}
      </Box>

      <Grid.Row justifyContent="center">
        <Show.Mobile>
          <Image
            src={mobile?.src}
            alt={mobile?.alt}
            loading={mobile?.loading}
            maxWidth="unset"
            height="100%"
            {...mobile?.position}
          />
        </Show.Mobile>
        <Show.TabletOnly>
          <Image
            src={tablet?.src}
            alt={tablet?.alt}
            loading={tablet?.loading}
            maxWidth="unset"
            height="100%"
            {...tablet?.position}
          />
        </Show.TabletOnly>
      </Grid.Row>
      <Box mt={{ sm: 16 }}>
        <AvailablePlatforms variant={theme.variants?.availablePlatforms} centered />
      </Box>
    </Grid.Container>
  );
};

export default DealsCTATablet;

import React from 'react';

import { Show } from '@core';
import { SectionContainerSC as SectionContainer } from '@components';

import { DealsCTADesktop, DealsCTATablet } from './layouts';

const DealsCTASection = (props) => (
  <SectionContainer
    data-section-id="CTA"
    containerProps={{ position: 'relative' }}
    wrapperProps={{ pb: 0 }}
  >
    <Show.TabletLarge>
      <DealsCTATablet {...props} />
    </Show.TabletLarge>

    <Show.DesktopAndUp>
      <DealsCTADesktop {...props} />
    </Show.DesktopAndUp>
  </SectionContainer>
);

export default DealsCTASection;

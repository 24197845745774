import React from 'react';
import { useTheme } from 'styled-components';

import { Box } from '@core';
import { NewsOutletsRow } from '@containers';

import { DealsCTASection } from './deals-cta-section';
import { DealsNavBar } from './components';

const DealsHeader = ({
  ctaData,
  showLanguagePicker,
  newsOutlets,
  showTimer = true,
  showStoreRatings = true,
}) => {
  const theme = useTheme();

  return (
    <Box data-section-id="DealsHeader" overflow="hidden" position="relative" zIndex={10}>
      <DealsNavBar showLanguagePicker={showLanguagePicker} />
      <DealsCTASection data={ctaData} showTimer={showTimer} showStoreRatings={showStoreRatings} />
      <NewsOutletsRow
        title={newsOutlets?.title}
        logos={newsOutlets?.logos}
        isWithLink={newsOutlets?.isWithLink}
        titleProps={{ color: theme.newsOutlets?.titleColor || 'darkBlue' }}
        logoColor={theme.newsOutlets?.logoColor}
      />
    </Box>
  );
};

export default DealsHeader;

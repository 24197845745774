import React from 'react';
import { useTheme } from 'styled-components';

import { Grid } from '@core';
import { ImageCore, LOADING_OPTIONS, LanguagePicker } from '@components';
import AtlasLogoWhite from '@images/logos/atlas-logo-white.svg';
import AtlasLogo from '@images/logos/atlas-logo-white.svg';

const DealsNavBar = ({ showLanguagePicker }) => {
  const theme = useTheme();

  return (
    <Grid.Container data-section-id="DealsNavBar" my={28}>
      <Grid.Row
        position="relative"
        height={24}
        maxWidth={1280}
        px={{ _: 24, md: 40 }}
        justifyContent="space-between"
        alignItems="center"
      >
        <ImageCore
          src={theme.variants?.navBarLogo === 'white' ? AtlasLogoWhite : AtlasLogo}
          alt="Atlas VPN logo"
          loading={LOADING_OPTIONS.eager}
          height="100%"
        />
        {showLanguagePicker && (
          <LanguagePicker wrapperProps={{ ml: { md: 32, lg: 48 }, mr: { md: 16, lg: 0 } }} />
        )}
      </Grid.Row>
    </Grid.Container>
  );
};

export default DealsNavBar;

import React from 'react';
import styled, { keyframes } from 'styled-components';

import { Box } from '@core';
import { ImageCore } from '@components/image';
import screen1 from '@images/deals/animation/screen-1.png';
import screen2 from '@images/deals/animation/screen-2.png';
import screen3 from '@images/deals/animation/screen-3.png';
import screen4 from '@images/deals/animation/screen-4.png';
import { query } from '@shared/style-helpers';

const animation = keyframes`
  from {
    transform: translateX(0);
  }
`;

const Image = styled(ImageCore)`
  position: absolute;
  right: 20%;
  bottom: 0;

  animation: ${animation};
  transition-timing-function: ease-in-out;
  animation-duration: 2s;

  transform: ${({ finalPosition }) => `translateX(${finalPosition.md}px)`};

  @media ${query.maxTabletSmall} {
    transform: ${({ finalPosition }) => `translateX(${finalPosition._}px)`};
  }
`;

const AnimatedImage = () => {
  return (
    <Box position="relative" width={{ _: 250, md: 400 }} height="100%">
      <Image
        finalPosition={{ _: -100, md: -200 }}
        mb={{ _: 20, md: 35 }}
        src={screen4}
        alt="streaming"
        height={{ _: 220, md: 360 }}
      />
      <Image
        finalPosition={{ _: -50, md: -100 }}
        mb={{ _: 20, md: 35 }}
        src={screen3}
        alt="speed"
        height={{ _: 230, md: 375 }}
      />
      <Image
        finalPosition={0}
        mb={{ _: 15, md: 20 }}
        src={screen2}
        alt="privacy"
        height={{ _: 245, md: 400 }}
      />
      <Image
        finalPosition={{ _: 65, md: 110 }}
        src={screen1}
        alt="phone"
        height={{ _: 280, md: 455 }}
      />
    </Box>
  );
};

export default AnimatedImage;
